<template>
  <div v-editable="blok" class="constrain py-6 lg:py-24 relative z-10">
    <h3 :class="`heading-2 text-${titlePosition}`">{{ blok.title }}</h3>
    <p :class="`text-grey-dark text-${titlePosition}`">{{ blok.subtitle }}</p>

    <!-- Region Selector with Horizontal Scrolling -->
    <div v-if="blok.show_group_names" class="flex flex-nowrap md:flex-wrap md:justify-center overflow-x-auto w-full md:max-w-[1000px] mx-auto gap-4 pt-4 lg:pt-16">
      <div
        v-for="group in blok.locationGroups" :key="group.name"
        @click="setActiveGroup(group.name)"
        :class="[
          'whitespace-nowrap rounded-md py-2 px-6 text-lg font-semibold text-center cursor-pointer',
          'hover:bg-[#F8F4FF] hover:text-primary',
          group.name === activeGroup ? 'bg-[#F8F4FF] text-primary' : 'text-grey-5'
        ]"
      >
        {{ group.name }}
      </div>
    </div>

    <!-- Location List for Active Region -->
    <div
      class="pt-4 grid grid-cols-2 lg:grid-cols-5 gap-4 lg:gap-12"
      :class="{
        'lg:mt-16': blok.show_group_name,
        'mt-4': !blok.show_group_name
      }"
    >
      <template v-for="locationGroup in blok.locationGroups">
        <LocationsLocationCard
          v-if="locationGroup.name === activeGroup"
          v-for="location in locationGroup.locations"
          :location="location"
          :loctaionGroup="locationGroup"
          :key="location"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({ blok: Object });

const activeGroup = ref("Popular");

const setActiveGroup = (groupName) => {
  activeGroup.value = groupName;
};

const titlePosition = computed(() => {
  return props.blok.title_position || 'center';
});

onMounted(() => {
  setActiveGroup(props.blok.locationGroups[0].name);
});
</script>
