<template>
  <NuxtLink
    v-editable="location"
    :to="`/`+location.link.cached_url"
  >
    <div
      class="border-2 border-grey-1 bg-white rounded-lg lg:rounded-xl py-2 lg:py-8 text-center flex flex-col justify-center items-center hover:bg-[#F8F4FF] hover:cursor-pointer hover:text-primary"
    >
      <!-- Static Map Image -->
      <div class="relative w-28 h-28 mx-auto">
        <img
          :src="`${getStaticMapUrl(location.name)}`"
          class="w-28 h-28 border-2 border-neutrals-200 rounded-full"
          :alt="`Map of ${location.name} for ${ location.title }`"
          loading="lazy"
          width="180"
          height="180"
        />
        <CommonSVGTemplate
          :path="marker"
          width="24px"
          height="24px"
          class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
      </div>

      <p class="text-sm mt-6">{{ location.title }}</p>
      <p class="text-lg font-medium mt-1">{{ location.name }}</p>
    </div>
  </NuxtLink>
</template>
<script setup>
const props = defineProps({
  location: Object,
});

const runtimeConfig = useRuntimeConfig();

const marker = `
<path d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C13.5897 22.48 10.4097 22.48 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z" fill="#28C700" stroke="#28C700" stroke-width="1.5"/>
<path d="M11.9989 13.4299C13.722 13.4299 15.1189 12.0331 15.1189 10.3099C15.1189 8.58681 13.722 7.18994 11.9989 7.18994C10.2758 7.18994 8.87891 8.58681 8.87891 10.3099C8.87891 12.0331 10.2758 13.4299 11.9989 13.4299Z" fill="#FAFAFA" stroke="#FAFAFA" stroke-width="1.5"/>
`;

const getStaticMapUrl = (location) => {
  const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap';
  const params = new URLSearchParams({
    key: runtimeConfig.public.googleMapsKey,
    center: `${location}, UK`,
    zoom: '13',
    size: '180x180',
    maptype: 'roadmap',
  });
  return `${baseUrl}?${params.toString()}`;
};
</script>
